import { useDateFormat } from '@vueuse/core'
import locales from '~/i18n/locales'

export default function useDate() {
  return {
    dateFormatMonthYear: (date: Date, locale: string = 'en') =>
      useDateFormat(date, 'MMMM YYYY', {
        locales: locales.find((l) => l.code === locale)?.iso
      }).value,
    dateFormatYear: (date: Date, locale: string = 'en') =>
      useDateFormat(date, 'YYYY', {
        locales: locales.find((l) => l.code === locale)?.iso
      }).value
  }
}
